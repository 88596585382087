import {Controller} from "@hotwired/stimulus"
import {Jodit} from 'jodit'

export default class extends Controller {

    connect() {
        Jodit.make(this.element,
            {
                imageProcessor: {
                    replaceDataURIToBlobIdInView: false
                },
                "buttons": "bold,italic,underline,ul,ol,link,source",
                "toolbarAdaptive": false
            }
        );
    }
}