window.new_mail_form_set_language = function (language) {
    event.preventDefault()
    const buttons = document.querySelectorAll('#language-buttons button')


    const inputs = document.querySelectorAll('.language-inputs')
    for (const inp of inputs) {
        inp.classList.add('hide')
    }


    for (const btn of buttons) {
        const input = document.querySelector(`#language-inputs-${language}`)
        if (btn.id === `language-${language}`) {
            btn.classList.add('active')
            input.classList.remove('hide')
        } else {
            btn.classList.remove('active')
        }
    }
}