<script>
    const type = 'light'

    export let tooltip = ''
    export var tooltipHoverDelay = 1000
    export let icon = '?'

    const error = `Icon not defined: ${type}/${icon}`
    var js = {}
    const js1 = JSON.parse(document.querySelector('meta#fontawesome').getAttribute('content'))
    if (js1.hasOwnProperty(type)) {
        js = js1[type][icon]
    } else {
        console.error(error)
    }
</script>
<template>
    {#if !js1.hasOwnProperty(type)}
        <strong>{error}</strong>
    {:else if tooltip !== '' }
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="{js.viewBox}" data-tooltip title="{tooltip}" data-hover-delay="{tooltipHoverDelay}">
            {@html '<!--! ' + js.license + ' --!>'}
            <path d="{js.pathD}"/>
        </svg>
    {:else}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="{js.viewBox}">
            {@html '<!--! ' + js.license + ' --!>'}
            <path d="{js.pathD}"/>
        </svg>
    {/if}
</template>