window.show_password = function() {
    var i = document.getElementById("password-input-field");

    if (i.type === "password") {
        i.type = "text";
    } else {
        i.type = "password";
    }

    var i2 = document.getElementById("password-input-field2");

    if (i2.type === "password") {
        i2.type = "text";
    } else {
        i2.type = "password";
    }
};


window.generate_password = function() {
    var e = document.getElementById('generated-password');
    e.style.display = 'block';
    var xhr = new XMLHttpRequest();
    xhr.open('GET', "/sessions/generate_password", false);
    xhr.send();
    e.innerHTML = xhr.responseText;
};