import {Controller} from "@hotwired/stimulus"
import 'axios'
import axios from "axios";
import getCSRFToken from '@shopify/csrf-token-fetcher';
//import * as utf8 from 'utf8';

export default class extends Controller {

    connect() {
        const preview_button = document.getElementById('preview-button')

        this.element.addEventListener('click', (e) => this.show_edit(e, preview_button))

        preview_button.addEventListener('click', (e) => this.show_preview(e, preview_button))
    }

    show_edit(e, preview_button) {

        preview_button.classList.remove('active')
        this.element.classList.add('active')

        const edit_box = document.getElementById('language-inputs-default')
        edit_box.classList.remove('hide')

        const preview_box = document.getElementById('preview-box')
        preview_box.classList.add('hide')



    }

    show_preview(e, preview_button) {

        preview_button.classList.add('active')
        this.element.classList.remove('active')

        const edit_box = document.getElementById('language-inputs-default')
        edit_box.classList.add('hide')

        const preview_box = document.getElementById('preview-box')
        preview_box.classList.remove('hide')

        const subject_input = document.getElementById('sent_mail_subject')
        const body_input = document.getElementById('sent_mail_body')
        const project_id = this.element.getAttribute('data-project-id')





        const body = body_input.value

       // const body_enc = this.toHex(utf8.encode(body))
        const body_enc = this.toHex(body)

        axios.get('/mails/mail_preview?subject=' + encodeURI(subject_input.value) + '&body=' + body_enc + '&project_id=' + project_id )
            .then(function (response) {
                preview_box.innerHTML = response.data
            })
    }

    toHex(txt){
        const encoder = new TextEncoder();
        return Array
            .from(encoder.encode(txt))
            .map(b => b.toString(16).padStart(2, '0'))
            .join('')
    }

}