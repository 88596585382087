import axios from "axios";

window.close_dropdown_pane = function () {
    const e = event.target;
    $(e).closest('.dropdown-pane').foundation('close')
}

export function initialize_popovers() {

    $('#top-left-menu-panel').on('dropdown-open', (e) => open_top_left_menu_panel(e))

    $('#projects-dropdown-panel').on('dropdown-open', (e) => open_projects_dropdown_panel(e))

    $('.people-in-radius-dropdown-panel').on('dropdown-open', (e) => open_people_in_radius_project_dropdown(e))

}

function open_projects_dropdown_panel(event) {
    const panel_id = event.target.id
    const project_id = panel_id.split('-')[panel_id.split('-').length - 1]
    axios.get('/dropdown/projects_dropdown').then(function(response) {
        const panel = event.target
        const cont = panel.querySelector('.content-wrapper')
        cont.innerHTML = response.data
    })
}

function open_top_left_menu_panel(event) {
    axios.get('/dropdown/top_left_menu').then(function(response) {
        const panel = event.target
        const cont = panel.querySelector('.content-wrapper')
        cont.innerHTML = response.data
    })
}



function open_people_in_radius_project_dropdown(event) {
    const panel_id = event.target.id
    const project_id = panel_id.split('-')[panel_id.split('-').length - 1]
    axios.get('/projects/people_in_radius_dropdown?project_id=' + project_id).then(function(response) {
        const panel = event.target
        const cont = panel.querySelector('.content-wrapper')
        cont.innerHTML = response.data
    })
}