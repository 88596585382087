window.toggle_form_part = function(key) {
    var part = document.getElementById(key + '-part');
    var up = document.getElementById(key + '-up');
    var down = document.getElementById(key + '-down');
    var vis = part.style.display;
    if (vis == 'inline-block') {
        part.style.display = 'none';
        up.style.display = 'none';
        down.style.display = 'inline-block';
    } else {
        part.style.display = 'inline-block';
        up.style.display = 'inline-block';
        down.style.display = 'none';
    }

};