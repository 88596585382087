window.initOverviewMap = function () {
    console.log('initialize-map')
    const wrapper = document.getElementById('map-wrapper')
    if (wrapper) {
        console.log('have-wrapper')
        window.setTimeout(
            () => doInitOverviewMap(wrapper),
            100
        )
    }
}

function doInitOverviewMap(wrapper) {
    console.log('do scrolling')
    const map = document.getElementById('map')

    const wr_width = wrapper.offsetWidth;
    const wr_height = wrapper.offsetHeight;

    const map_width = map.offsetWidth;
    const map_height = map.offsetHeight;

    const mp = document.getElementById('my-position').innerHTML
    const my_pos = JSON.parse(mp)

    var scr_x = 0
    var scr_y = 0

    if (wr_width < map_width) {
        scr_x = my_pos.x - (wr_width / 2)
    }
    if (wr_height < map_height) {
        scr_y = my_pos.y - (wr_height / 2)
    }
    wrapper.scroll(scr_x, scr_y)
    window.scrollTo(0, 400);
}