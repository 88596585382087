window.mails_open_details = function(id) {
    document.getElementById('mails-details-open-button-' + id).style.display = 'none';
    document.getElementById('mails-details-close-button-' + id).style.display = 'block';
    document.getElementById('mails-body-' + id).classList.remove('hidden');
};

window.mails_close_details = function(id) {
    document.getElementById('mails-details-close-button-' + id).style.display = 'none';
    document.getElementById('mails-details-open-button-' + id).style.display = 'block';
    document.getElementById('mails-body-' + id).classList.add('hidden');
};

window.mail_form_change_project = function() {
    var v = document.getElementById('mail_project_id').value;
    var e = document.getElementById('mail_webkeys_antenna_objection').parentElement;
    if (v == '') {
        e.style.display = 'none';
    } else {
        e.style.display = 'block';
        var wk = document.getElementById('mail_webkeys_geo_admin');
        wk.checked = 'checked';
    }
};

window.add_file_field = function() {

    //event.target.after("<input name='file[]' type='file'/>");
     var u = document.getElementById('uploader-wrapper');
    u.insertAdjacentHTML('afterend', "<div class='file-uploader-wrapper'><input name='mail[attachment][]' type='file'/><span onclick='remove_file_field()'>⨂</span></div>")
     //u.innerHTML += u.innerHTML ;
}

window.remove_file_field = function() {
    var e = event.target.parentNode;
    e.remove();
}