import 'axios'
import axios from "axios";

window.set_coordinates_manual = function () {
    var e = document.getElementById("project_coordinates_manual");
    e.checked = true;
};

$('.users-projects-info-dropdown').on('dropdown-open', (e) => open_users_projects_dropdown(e))

function open_users_projects_dropdown(e) {
    const user_id = e.target.id.split('-')[e.target.id.split('-').length - 1]
    axios.get('/dropdown/user_projects_info?user_id=' + user_id).then(function (response) {
        e.target.querySelector('.content-wrapper').innerHTML = response.data
    })

}

