window.disable_address = function (address_id) {
    var e = event.target;
    var tr = e.parentNode.parentNode;
    var td = e.parentNode;
    td.querySelector('.enable-button').classList.remove('hidden');
    e.classList.add('hidden');
    tr.classList.add('strike-through');
    tr.classList.add('grey');
    var req = new XMLHttpRequest();
    req.open("GET", "disable_address?address_id=" + address_id,true);
    req.send(null);
};

window.enable_address = function (address_id) {
    var e = event.target;
    var tr = e.parentNode.parentNode;
    var td = e.parentNode;
    td.querySelector('.disable-button').classList.remove('hidden');
    e.classList.add('hidden');
    tr.classList.remove('strike-through');
    tr.classList.remove('grey');
    var req = new XMLHttpRequest();
    req.open("GET", "enable_address?address_id=" + address_id,true);
    req.send(null);
};

window.doubles_show_all_addresses = function() {
    document.getElementById('doubles-show-all-addresses-button').style.display = 'none';
    objects = document.getElementsByClassName('double-address-done')
    for (var e in objects) {
        objects[e].classList.remove("double-address-done")
    }
}