import component from "svelte-tag"

import App from './location-pane.svelte'
new component({ component: App, tagname: "location-pane" })

import fal from './fal-svg.svelte'
new component({ component: fal, tagname: "fal-svg" })
import far from './far-svg.svelte'
new component({ component: far, tagname: "far-svg" })
import fas from './fas-svg.svelte'
new component({ component: fas, tagname: "fas-svg" })

console.log('svelte-inited!')