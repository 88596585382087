window.locating_popover_open = function(event, id) {
    var ex = event.pageX;
    var ey = event.pageY;
    var wy = window.innerHeight - 340;
    //var y = (ey < wy ? ey : wy);
    var wrapper = document.getElementById('locating-popover-' + id);
    if (id == 'my-account') {
        wrapper.style.left = '-300px';
        wrapper.style.top = '10px';
    } else {
        wrapper.style.left = (ex - 495) + 'px';
        wrapper.style.top = (ey - 270) + 'px';
    }
    wrapper.style.visibility = 'visible';
    wrapper.style.width = '511px';
    wrapper.style.height = '386px';
    wrapper.innerHTML = 'processing...';


    var xhr = new XMLHttpRequest();
    xhr.open('GET', "/admin/locating_popover?id=" + id, false);
    xhr.send();
    wrapper.innerHTML = xhr.responseText;
    var input = document.getElementById('locating-popover-input-' + id);
    var val = input.value;
    input.select();
    input.value = '';
    input.value = val;

    location_popover_calculate(id);
};

window.locating_popover_close = function(id) {
    var wrapper = document.getElementById('locating-popover-' + id);
    wrapper.style.visibility = 'hidden';
    wrapper.innerHTML = '';
    wrapper.style.width = '0px';
    wrapper.style.height = '0px';
};

window.location_popover_keyup = function(event, id) {
    if (event.key == 'Enter') {
        location_popover_calculate(id);
    }
};

window.location_popover_calculate = function(id) {
    var input = document.getElementById('locating-popover-input-' + id);
    var str = input.value;
    var r = document.getElementById('locating-popover-map-' + id);
    r.innerHTML = "Suchen..";

    var xhr = new XMLHttpRequest();
    xhr.open('GET', "/admin/locate_address?id=" + id + '&address=' + encodeURI(str), false);
    xhr.send();
    var js = JSON.parse(xhr.responseText);
    if (js.message === 'SUCCESS') {
        r.innerHTML = "Karte generieren..";
        var m = document.getElementById('map-marker-' + id);
        m.classList.add('located');
        m.classList.remove('not-located');
        show_map_for_address(id, js.latitude, js.longitude);
    } else {
        r.innerHTML = js.message;
    }
};

window.show_map_for_address = function(id, latitude, longitude) {
    var e = document.getElementById('locating-popover-map-' + id);
    var xhr = new XMLHttpRequest();
    xhr.open('GET', "/admin/geo_map?latitude=" + latitude + "&longitude=" + longitude, false);
    xhr.send();
    e.innerHTML = xhr.responseText;
};