import {Controller} from "@hotwired/stimulus"
import {computePosition, flip, autoPlacement, shift, offset, arrow} from '@floating-ui/dom';

// adds the class .floating-dropdown-panel
// triggers the dropdown-open event on the panel

export default class extends Controller {

    connect() {
        // add reference to this stimulus instance to the element for being able calling this methods from outside
        this.element[this.identifier + '_controller'] = this

        if (this.element.hasAttribute('data-toggle')) {
            const target_id = this.element.getAttribute('data-toggle')
            const panel = document.getElementById(target_id)
            panel.classList.add('floating-dropdown-panel')
            this.element.addEventListener('click', () => this.toggle())
        }
    }

    toggle() {
        const target_id = this.element.getAttribute('data-toggle')
        const panel = document.getElementById(target_id)
        if (panel.classList.contains('hide')) {
            this.open(panel)
        } else {
            this.close(panel)
        }
    }

    open(panel) {

        panel.classList.remove('hide');

        this.set_position(panel)
        if (!window.dropdown_controller_close_all_on_click_outside_added) {
            window.dropdown_controller_close_all_on_click_outside_added = true;
            window.addEventListener('click', (e) => this.closeAllOnClickOutside(e))
        }

        if (panel.hasAttribute('data-set-focus')) {
            let data_focus = panel.getAttribute('data-set-focus')
            let focus_element = panel.querySelector(data_focus)
            focus_element.focus()
        }

        // trigger event
        const event = new CustomEvent('dropdown-open')
        panel.dispatchEvent(event)
    }

    close(panel) {
        panel.classList.add('hide')
    }

    closeAllOnClickOutside(ev) {
        if (!ev.target.closest(`[data-controller="${this.identifier}"]`)) {
            if (!ev.target.closest('.floating-dropdown-panel')) {
                const panels = document.getElementsByClassName('floating-dropdown-panel')
                for (const panel of panels) {
                    panel.classList.add('hide')
                }
            }
        }
    }

    set_position() {
        const t_id = this.element.getAttribute('data-toggle')
        let panel = document.getElementById(t_id)
        let arrow_element = panel.querySelector('#arrow')

        computePosition(this.element, panel, {
            middleware: [flip(), offset(6), shift({padding: 5}), arrow({element: arrow_element})]
        }).then(({x, y, placement, middlewareData}) => {
            Object.assign(panel.style, {
                left: `${x}px`,
                top: `${y}px`,
            });

            if (arrow_element) {

                //ARROW
                const {x: arrowX, y: arrowY} = middlewareData.arrow;
                const staticSide = {
                    top: 'bottom',
                    right: 'left',
                    bottom: 'top',
                    left: 'right',
                }[placement.split('-')[0]];
                Object.assign(arrow_element.style, {
                    left: arrowX != null ? `${arrowX}px` : '',
                    top: arrowY != null ? `${arrowY}px` : '',
                    right: '',
                    bottom: '',
                    [staticSide]: '-4px',
                });
            }
        });
    }
}