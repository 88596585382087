<script>

    import axios from 'axios';
    import jQuery from 'jquery';

    export let id = '?'
    export let lat
    export let long
    export let location_string
    export let controller

    let mapWrapper
    let hint
    let closeBtn

    let calc_lat_long = () => {
        mapWrapper.innerHTML = 'Calculating ..';
        axios.get(`/${controller}/calc_lat_long?string=` + location_string)
            .then(res => {
                lat = res.data.latitude;
                long = res.data.longitude;
                calc_map()
            })
    }

    let calc_map = () => {
        mapWrapper.innerHTML = 'Calculating ..';
        let u = `/${controller}/calc_map?lat=` + lat + '&long=' + long + '&id=' + id
        let url = encodeURI(u)
        console.log(`LOCATION-PANE-URL => ` + u, url)
        let xhr = new XMLHttpRequest();
        xhr.open('get', url)
        xhr.send()
        xhr.onload = function () {
            mapWrapper.innerHTML = xhr.response;
        };
    }

    let save = () => {
        console.log('ID => ' + id)
        axios.get(`/${controller}/save_location?lat=` + lat + '&long=' + long + '&string=' + location_string + '&id=' + id)
            .then(res => {
                console.log(hint.classList)
                hint.classList.remove('hide')
                hint.classList.add('error')
                console.log(hint.classList, res.data)
                if (res.data.success) {
                    hint.classList.remove('error')
                    hint.innerHTML = 'Erfolgreich gespeichert'
                    console.log('success')
                } else {
                    hint.innerHTML = res.data.message
                    console.log('failed')
                }
                setTimeout(function () {
                        hint.classList.add('hide')

                    }
                    , 2000)
            })
            .catch(function (error) {
                hint.classList.remove('hide')
                hint.classList.add('error')
                hint.innerHTML = error
                setTimeout(function () {
                        hint.classList.add('hide')

                    }
                    , 4000)
            })
    }

    let close = () => {
        let pane = jQuery(closeBtn).closest('.dropdown-pane')
        pane.foundation('close');
    }
</script>

<template>
    <div class="location-string-wrapper location-wrapper">
        <input class="string" type="text" bind:value={location_string}/>
        <far-svg icon="rotate-right" tooltip="Latitude / Longitude neuberechnen" on:click={calc_lat_long}/>
        <far-svg icon="xmark" tooltip="Schliessen" on:click={close} bind:this={closeBtn}/>
    </div>
    <div class="location-lat-long-wrapper location-wrapper">
        <label>Latitude:</label>
        <input class="string" type="text" bind:value={lat}/>
        <label class="text-center">Longitude:</label>
        <input class="string" type="text" bind:value={long}/>
        <far-svg id="calc-btn-{id}" icon="rotate-right" tooltip="Karte aktualisieren" on:click={calc_map}/>
        <far-svg icon="floppy-disk" tooltip="Speichern" on:click={save}/>
    </div>
    <div class="locating-hint-wrapper">
        <div bind:this={hint} class="locating-hint hide"></div>
    </div>
    <div class="map-wrapper" bind:this="{mapWrapper}">

    </div>
</template>
