import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        const inp = document.getElementById('project_responsible_user_id')
        this.handleChanged(null, inp.value)
    }

    handleChanged(e, value) {
        let id
        if (e) {
            id = e.target.value;
        } else {
            id = value;
        }

        // passive users

        let passives = gon.passive_user_ids;
        let hint = document.getElementById('selected-passive-user')
        if (id === '') {
            hint.hidden = true
        } else {
            hint.hidden = !passives.includes(parseInt(id))
        }

        let inactives = gon.inactive_user_ids;
        console.log('inactive-ids: ' + inactives, 'id: ' + id)
        let hint2 = document.getElementById('selected-inactive-user')
        if (hint2) {
            if (id === '') {
                hint2.hidden = true
            } else {
                hint2.hidden = !inactives.includes(parseInt(id))
            }
        }
    }
}